import { UserRoleEnum, UserRoleType } from 'AppV2/interfaces/userRole';

export const setCustomClaim = (idTokenResult, setUserCustomClaim) => {
  const customClaimObj = {
    userRole: idTokenResult.claims.userRole,
    navigationStage: idTokenResult.claims.navigationStage,
    userIsAvailable: idTokenResult.claims.userIsAvailable,
    headerSubtitle: idTokenResult.claims.headerSubtitle,
  };

  setUserCustomClaim(customClaimObj);
};

type CreateCustomClaimParams = {
  accountType: UserRoleType;
  flag: {
    key: string;
    value: any;
  };
};
export const createFirstCustomClaim = ({
  ...params
}: CreateCustomClaimParams) => {
  let customClaims = {};

  if (params.accountType === UserRoleEnum.squadMember) {
    customClaims = {
      userRole: UserRoleEnum.squadMember,
      navigationStage: 'squadMember-register',
      headerSubtitle: '',
      userIsAvailable: false,
    };
  }

  if (params.accountType === UserRoleEnum.squadOwner) {
    customClaims = {
      userRole: UserRoleEnum.squadOwner,
      navigationStage: 'squadOwner-register',
    } as any;
  }

  if (params.accountType === UserRoleEnum.admin) {
    customClaims = {
      userRole: UserRoleEnum.admin,
      navigationStage: 'cms-selection',
      headerSubtitle: 'Grower',
      userIsAvailable: true,
    };
  }

  if (params.flag) {
    customClaims[params.flag.key] = params.flag.value;
  }

  return customClaims;
};

export const createUserPostObj = (currentUser, accountType, adminData) => {
  type fireStoreObj = {
    [key: string]: any;
    login: {
      providerId: string;
      uid: string;
      email: string;
      createData: number;
      userRole: string;
    };
    navigationFlow: {
      journey: string;
    };
    eGoi: {
      egoiAddTag: number;
    };
    experience: {
      jobTitle: string;
      isAvailable: boolean;
    };
    projects: Array<string>;
  };

  const fireStoreObj: fireStoreObj = {
    login: {
      providerId: currentUser.providerData[0].providerId,
      uid: currentUser.uid,
      email: currentUser.email,
      createData: Date.now(),
      userRole: 'squadMember',
    },
    navigationFlow: {
      journey: 'squadMember-register',
    },
    eGoi: {
      egoiAddTag: 17,
    },
    experience: {
      jobTitle: '',
      isAvailable: false,
    },
    projects: [],
  };

  if (accountType === 'squadOwner') {
    fireStoreObj.login.userRole = 'squadOwner';
    // fireStoreObj.navigationFlow.journey = 'project-brief-selection';
    fireStoreObj.navigationFlow.journey = 'squadOwner-register';
    fireStoreObj.eGoi.egoiAddTag = 25;
  }

  if (accountType === 'admin') {
    fireStoreObj.login.userRole = 'admin';
    fireStoreObj.navigationFlow.journey = 'cms-selection';
    fireStoreObj.personal = {
      firstName: adminData.personal.firstName,
      lastName: adminData.personal.lastName,
      displayName: adminData.personal.displayName,
      dob: adminData.personal.dob,
    };
    delete fireStoreObj.experience;
    delete fireStoreObj.projects;
  }

  return fireStoreObj;
};
